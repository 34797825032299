import { Injectable } from '@angular/core';
import { ParseProvider } from './parse.provider';
import { Subject } from 'rxjs';
import { Customer } from '../interfaces/customer';
import { Group } from '../interfaces/group';

interface ResultsById { }

@Injectable({
  providedIn: 'root'
})
export class CustomerProvider {
  public results: Parse.Object[] = [];
  public searchTerms: string[] = [];
  public searchString = '';
  public resultsUpdated: Subject<Parse.Object[]> = new Subject();
  private resultsById: ResultsById;
  private groups: Object = {};

  constructor(
    private parseProvider: ParseProvider
  ) {
    this.parseProvider.customer.resultsUpdated.subscribe(this.onResultsUpdate);
  }

  onResultsUpdate = (results) => {
    this.reset();
    this.iterateResults(results);
    this.results = this.sortResults();
    this.resultsUpdated.next(this.results);
  }

  getSearchResults(search: string) {
    if (search === this.searchString) {
      this.resultsUpdated.next(this.results);

      return;
    }

    this.searchString = search;
    const words = search.split(' ');
    this.searchTerms = [search];
    this.searchTerms = this.searchTerms.concat(words.filter(word => word.length > 1));
    this.parseProvider.customer.search(['firstName', 'lastName', 'email'], this.searchTerms);
  }

  iterateResults(results: Array<any>) {
    results.forEach(item => {
      this.collateResults(item);
    });
  }

  collateResults(item: Parse.Object) {
    if (this.resultsById[item.id]) {
      this.resultsById[item.id].weight += 1;
    } else {
      const newResult = {
        item: item,
        weight: 1,
        name: item.get('name')
      };

      this.resultsById[item.id] = newResult;
    }
  }

  sortResults(): any[] {
    const results = [];
    Object.keys(this.resultsById).forEach(id => {
      results.push(this.resultsById[id].item);
    });

    return results.sort(this.sortByWeight);
  }

  sortByWeight = (a, b): number => {
    if (this.resultsById[a.id].weight > this.resultsById[b.id].weight) {
      return -1;
    }

    if (this.resultsById[a.id].weight < this.resultsById[b.id].weight) {
      return 1;
    }

    return 0;
  }

  reset() {
    this.results = [];
    this.resultsById = {};
  }

  onError(message) {
    // TODO :: Alert user
    if (message.error) {
      console.log(message.error);
    } else {
      console.log('CustomerProvider :: Something went wrong', message);
    }
  }

  async create(customer: Customer, generateEmail: boolean) {
    if (generateEmail) {
      return await this.parseProvider.customer.createWithGenEmail(customer);
    }

    return await this.parseProvider.customer.create(customer);
  }

  async setGroup(group: Group) {
    return await this.parseProvider.customer.setGroup(group);
  }

  async setCustomerGroup(
    group: Parse.Object,
    customerId: string
  ) {
    return await this.parseProvider.customer.setCustomerGroup(group, customerId);
  }

  async getGroup(groupId: string) {
    if (this.groups.hasOwnProperty(groupId)) {
      return this.groups[groupId];
    }

    const group = await this.parseProvider.customer.getGroup(groupId);
    this.groups[group.id] = group;

    return group;
  }

  async getAllGroups() {
    return await this.parseProvider.customer.getAllGroups();
  }

  async updateGroup(
    groupId: string,
    inputGroup: Group
  ) {
    return await this.parseProvider.customer.updateGroup(groupId, inputGroup);
  }

  async deleteGroup(groupId: string) {
    return await this.parseProvider.customer.deleteGroup(groupId);
  }

  async getExternalId(customerId) {
    return await this.parseProvider.customer.getExternalId(customerId);
  }

  getField(
    customer: Parse.Object,
    field: string
  ) {
    let value = customer.get(field);
    const parent = customer.get('parent');

    if (
      customer.get('isChild')
      && typeof value === 'undefined'
      && typeof parent !== 'undefined'
    ) {
      value = parent.get(field);
    }

    return value;
  }
}
