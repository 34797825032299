import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { Customer } from '../../interfaces/customer';
import { Subject } from 'rxjs';
import { Group } from '../../interfaces/group';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ParseCustomerProvider {

  constructor(
    private navCtrl: NavController
  ) { }

  public resultsUpdated: Subject<Parse.Object[]> = new Subject();

  async search(
    columns: string[],
    searchTerms: string[]
  ) {
    const promises = searchTerms.map(async term => {
      const escapedTerm = term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const pattern = `.*${escapedTerm}.*`;
      const regEx = new RegExp(pattern);
      const queries = [];

      columns.forEach(column => {
        const innerQuery = new Parse.Query('Customer');
        innerQuery.matches(column, regEx, 'i')
          .limit(600);
        queries.push(innerQuery);
      });

      const query = Parse.Query.or(...queries);
      query.include('parent');

      return query.find();
    });

    const resultArray = await Promise.all(promises);
    let results = [];

    resultArray.forEach(result => {
      results = results.concat(result);
    });

    this.resultsUpdated.next(results);
  }

  async create(data: Customer) {
    const email = data.email;
    const firstName = data.firstName
      ? data.firstName
      : 'Shop';
    const lastName = data.lastName
      ? data.lastName
      : 'Customer';

    return Parse.Cloud.run('backend-customer::create', {
      email: email,
      firstName: firstName,
      lastName: lastName,
      attributes: []
    });
  }

  async createWithGenEmail(data: Customer) {
    return Parse.Cloud.run('backend-customer::createWithGenEmail', {
      firstName: data.firstName,
      lastName: data.lastName,
      attributes: []
    });
  }

  async setCustomerGroup(
    group: Parse.Object,
    customerId: string
  ) {
    try {
      const query = new Parse.Query('Customer');
      const customer = await query.get(customerId);
      customer.set('group', group);

      return await customer.save();
    } catch (e) {
      this.handleParseError(e);
    }
  }

  setGroup(groupInput: Group) {
    try {
      const Group = Parse.Object.extend('Group');
      const group = new Group();
      group.set('name', groupInput.name);
      group.set('discounted', groupInput.discounted);
      group.set('discount', groupInput.discount);

      return group.save();
    } catch (e) {
      this.handleParseError(e);
    }
  }

  async getGroup(groupId: string) {
    try {
      const query = new Parse.Query('Group');

      return await query.get(groupId);
    } catch (e) {
      this.handleParseError(e);
    }
  }

  async getAllGroups() {
    try {
      const query = new Parse.Query('Group');
      query.descending('createdAt');

      return await query.find();
    } catch (e) {
      this.handleParseError(e);
    }
  }

  async updateGroup(
    groupId: string,
    inputGroup: Group
  ) {
    try {
      const query = new Parse.Query('Group');
      const group = await query.get(groupId);
      group.set('name', inputGroup.name);
      group.set('discounted', inputGroup.discounted);
      group.set('discount', inputGroup.discount);

      return await group.save();
    } catch (e) {
      this.handleParseError(e);
    }
  }

  async deleteGroup(groupId: string) {
    try {
      const query = new Parse.Query('Group');
      const result = await query.get(groupId);

      return result.destroy();
    } catch (e) {
      this.handleParseError(e);
    }
  }

  log(
    message: string,
    level?: number
  ) {
    // TODO :: Handle levels
    console.log('ParseProductProvider :: ' + message);
  }

  handleParseError(error) {
    console.log('handling parse error', error);
    switch (error.code) {
      case Parse.Error.INVALID_SESSION_TOKEN:
        console.log('invalid session token, logging out');
        Parse.User.logOut();
        this.navCtrl.navigateRoot('/');
        break;
    }
  }

  async getExternalId(customerId) {
    try {
      return Parse.Cloud.run('backend-customer::getExternalId', {
        customerId: customerId
      });
    } catch (e) {
      console.log(e);
    }
  }
}
